<template>
  <div v-show="form.insurances.length > 0">
    <div class="unicus-title mt-3 mb-3">
      <span>В кредит включено</span>
    </div>
    <div
      class="row mb-3 insurance-block-item"
      v-for="(item, i) in form.insurances"
      :key="i"
    >
      <div class="col-4">{{ item.product }}</div>
      <div class="col-2 pa-0">
        {{
          getInsuranceAdditionalConditionName(
            item.include_in_credit,
            item.issued_in_bank
          )
        }}
      </div>
      <div class="col pr-0">
        <span v-show="item.company" class="pr-1">{{ item.company }}</span>
        <nobr>{{ item.amount | pretty_numeric }}</nobr>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InsuranceBlock",

  computed: {
    form() {
      return this.$store.state.Credit.application.data;
    }
  },

  methods: {
    getInsuranceAdditionalConditionName(include_in_credit, issued_in_bank) {
      return this.$store.getters["Credit/getInsuranceAdditionalConditionName"](
        include_in_credit,
        issued_in_bank
      );
    }
  }
};
</script>
