<template>
  <div class="files-block">
    <v-layout
      v-show="
        loading === true || ($refs.upload && $refs.upload.uploaded === false)
      "
      align-center
      justify-center
      column
    >
      <v-progress-circular
        :size="30"
        :width="3"
        color="primary"
        indeterminate
        class="mb-1"
      />
      <small>Загрузка файлов...</small>
    </v-layout>

    <div
      v-show="
        loading === false && $refs.upload && $refs.upload.uploaded === true
      "
    >
      <file-upload
        :post-action="postAction"
        :headers="headers"
        :extensions="extensions"
        :accept="accept"
        :size="size"
        @input-file="uploadFile"
        ref="upload"
        :drop="true"
        multiple
        class="files-block__btn color-main"
      >
        <span class="museo-500 fs-17">
          Нажмите или перетащите сюда файл для загрузки
        </span>
      </file-upload>
    </div>

    <div v-show="files" class="mt-3">
      <div
        class="row mb-2"
        v-for="file in files.filter(_file => _file.is_deleted === false)"
        :key="file.id"
      >
        <div class="col-9">
          <span class="color-main fs-14" @click.stop="openFile(file.id, hash)">
            {{ file.name }}
          </span>
          <svg-icon-cross
            @click.stop="deleteFile(file.id, hash)"
            style="margin-bottom: -3px;"
            class="cursor-pointer"
          />
        </div>
        <div class="col-3 text-right align-self-center">
          <small class="color-label" style="font-size: 9px">
            {{ format_date(file.date) }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import svgIconCross from "@/assets/icons/cross.svg?inline";

const ACCEPT_FILES = [
  "application/msword", // doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
  "application/vnd.ms-excel", // xls
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
  ".xlsm",
  "image/*", // gif, jpg, jpeg, png
  "application/pdf" // pdf
];

const EXTENSIONS_FILES = [
  "doc",
  "docx",
  "xlsx",
  "xls",
  "pdf",
  "gif",
  "jpg",
  "jpeg",
  "png",
  "webp",
  "xlsm"
];

export default {
  name: "FilesBlock",
  components: { svgIconCross },

  props: {
    hash: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      extensions: EXTENSIONS_FILES.join(","),
      accept: ACCEPT_FILES.join(","),
      size: 1024 * 1024 * 10,
      headers: {
        Authorization: "Bearer " + this.$store.getters["Account/GetToken"]
      },
      postAction:
        process.env.VUE_APP_API_HOST +
        "/crediting/applications/get-by-hash/" +
        this.$props.hash +
        "/upload-docs",

      loading: true,
      errorsText: {
        extension: "Недопустимый формат документа",
        size: "Недопустимый размер файла"
      }
    };
  },

  computed: {
    files() {
      return this.$store.state.Credit.files;
    }
  },

  async mounted() {
    try {
      await this.$store.dispatch("Credit/initFiles", this.$props.hash);
    } catch (e) {
      this.loading = false;
    }
    this.loading = false;
  },

  methods: {
    uploadFile(newFile, oldFile) {
      this.loading = true;

      if (newFile && oldFile) {
        if (newFile.error && newFile.error !== oldFile.error) {
          let errorText = this.errorsText[newFile.error];
          if (!errorText) errorText = "Ошибка загрузки файла";

          this.$store.dispatch("Errors/addError", errorText);
          this.loading = false;
        }

        if (newFile.success !== oldFile.success) {
          this.$store.commit("Credit/initFiles", newFile.response);
        }
      }

      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }

      this.loading = false;
    },
    format_date(value) {
      if (!value || !moment(value).isValid()) return "";

      let thisDate = moment().format("DD.MM.YYYY");
      let date = moment(value).format("DD.MM.YYYY");

      if (thisDate !== date) return date;

      const minutes = moment().diff(moment(value), "minutes");
      const hours = moment().diff(moment(value), "hours");

      return (
        (hours > 0 ? hours + "ч. " : "") +
        (minutes - hours * 60) +
        " мин. назад"
      );
    },
    async openFile(id, hash) {
      await this.$store.dispatch("Credit/getDataFile", {
        hash,
        id
      });

      this.$refs[id][0].click();
    },
    async deleteFile(id, hash) {
      await this.$store.dispatch("Credit/deleteFile", {
        hash,
        id
      });
    }
  }
};
</script>

<style lang="stylus">
.files-block
  &__btn
    display flex !important
    justify-content center
    align-items center
    height 70px
    border 2px dashed
    border-radius 5px
    background-color #f2f4fa
    box-shadow 0 1px 10px 0 rgba(111, 147, 175, 0.5)
</style>
