import CreditFile from "../models/credit-file";

export default {
  setState(state, { prop, value } = {}) {
    if (!state || !prop || !state.hasOwnProperty(prop) || value === undefined)
      return;

    state[prop] = value;
  },
  setDataApplication(state, { prop, value } = {}) {
    if (
      !state ||
      !state.hasOwnProperty("application") ||
      !state.application.hasOwnProperty("data") ||
      !prop ||
      !state.application.data.hasOwnProperty(prop) ||
      value === undefined
    )
      return;

    state.application.data[prop] = value;
  },
  setDateSolution(state, v) {
    state.application.data.date_solution = v;
  },
  setLastButton: function(state, type) {
    state.application.last_button = type;
  },
  initFiles(state, list = []) {
    if (!state || !state.hasOwnProperty("files")) return;

    state.files = [];
    list.forEach(item => {
      let file = new CreditFile();
      file.setProps(item);
      state.files.push(file);
    });

    state.files.sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    });
  },
  setPropFileByID(state, { prop, value, id }) {
    if (!state || !state.hasOwnProperty("files")) return;
    const index = state.files.findIndex(file => file.id === id);
    if (index < 0 || !state.files[index].hasOwnProperty(prop)) return;
    state.files[index][prop] = value;
  }
};
