import axios from "axios";
import store from "@/bootstrap/store";

function getInstance(token) {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_HOST,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    }
  });

  instance.interceptors.response.use(
    response => {
      if (response.data.hasOwnProperty("error") && response.data.error) {
        let errors = response.data.error;

        if (Array.isArray(errors)) {
          store.dispatch("Errors/addError", errors.join("<br>"));
        } else {
          store.dispatch("Errors/addError", errors);
        }
      }

      return response;
    },
    error => {
      let errors = "";
      if (error.response.status === 401) {
        store.dispatch("Errors/showErrorToken");
      } else if (
        error.response.status === 405 ||
        error.response.status === 500
      ) {
        store.dispatch(
          "Errors/addError",
          "Сервер временно недоступен, попробуйте позже!!!"
        );
      } else {
        if (
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          errors = error.response.data.error;

          if (Array.isArray(errors)) {
            errors = errors.join("<br>");
          }
        }
      }

      store.dispatch("Errors/addError", errors);
      return Promise.reject(error.response);
    }
  );

  return instance;
}

export function isRealApi() {
  return (
    (!process.env.VUE_APP_WITHOUT_BACKEND && true) ||
    process.env.NODE_ENV === "production"
  );
}

export default callback => {
  const token = store.getters["Account/GetToken"];
  return callback(getInstance(token));
};
