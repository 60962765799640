<template>
  <div>
    <div class="row mb-3">
      <div class="col-auto">
        <span class="unicus-title">
          Параметры одобрения
        </span>
      </div>
      <div class="col text-right">
        <span class="unicus-title color-error">
          {{ form.transport }}
        </span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <div class="unicus-field-params" style="width: 490px">
          <unicus-select
            v-model="form.program"
            item-text="name"
            item-value="id"
            :items="program_list"
            hide-details
            label=" "
            v-validate="getRules('program')"
            data-vv-scope="form"
            data-vv-delay="300"
            :class="{ 'has-error': errors.has('form.program') }"
            data-vv-as="Программа кредитования"
            name="program"
            :disabled="disabled"
          ></unicus-select>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-8 align-self-center">Срок кредита</div>
      <div class="col-auto pr-0 text-xs-right">
        <div class="unicus-field-params" style="width: 90px; margin-left: 20px">
          <unicus-select
            :menu-props="{ maxHeight: 400 }"
            v-model="form.credit_period"
            item-text="name"
            item-value="id"
            :items="credit_periods"
            label=" "
            hide-details
            v-validate="getRules('credit_period')"
            data-vv-scope="form"
            data-vv-delay="300"
            :class="{ 'has-error': errors.has('form.credit_period') }"
            name="credit_period"
            :disabled="disabled"
          ></unicus-select>
        </div>
      </div>
      <div class="col align-self-center pl-0"></div>
    </div>
    <div class="row mb-2">
      <div class="col-8 align-self-center">Процентная ставка</div>
      <div class="col-auto pr-0 text-xs-right">
        <div class="unicus-field-params" style="width: 40px;margin-left: 60px">
          <unicus-autonumeric-field
            v-model="form.rate"
            hide-details
            label=" "
            data-vv-scope="form"
            v-validate="getRules('rate')"
            data-vv-delay="300"
            :class="{ 'has-error': errors.has('form.rate') }"
            data-vv-as="Процентная ставка"
            name="rate"
            :disabled="disabled"
            :max-value="99.99"
          ></unicus-autonumeric-field>
        </div>
      </div>
      <div class="col align-self-center pl-0">
        <i
          class="fal fa-percent"
          :class="{ 'error--text': errors.has('form.rate') }"
        ></i>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-8 align-self-center">
        Стоимость ТС
      </div>
      <div class="col-auto pr-0 text-xs-right">
        <div class="unicus-field-params">
          <unicus-autonumeric-field
            v-model="form.approved_transport_cost"
            hide-details
            label=" "
            v-validate="getRules('approved_transport_cost')"
            data-vv-delay="300"
            :class="{
              'has-error': errors.has('form.approved_transport_cost')
            }"
            data-vv-scope="form"
            data-vv-as="Стоимость ТС"
            name="approved_transport_cost"
            :disabled="disabled"
          ></unicus-autonumeric-field>
        </div>
      </div>
      <div class="col align-self-center pl-0">
        <i
          class="fal fa-ruble-sign"
          :class="{
            'error--text': errors.has('form.approved_crdt_sum_transport')
          }"
        ></i>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-8 align-self-center">Одобренная сумма кредита</div>
      <div class="col-auto pr-0 text-xs-right">
        <div class="unicus-field-params">
          <unicus-autonumeric-field
            v-model="form.approved_crdt_sum"
            hide-details
            label=" "
            data-vv-scope="form"
            v-validate="getRules('approved_crdt_sum')"
            data-vv-delay="300"
            :class="{ 'has-error': errors.has('form.approved_crdt_sum') }"
            data-vv-as="Одобренная сумма кредита"
            name="approved_crdt_sum"
            :disabled="disabled"
          ></unicus-autonumeric-field>
        </div>
      </div>
      <div class="col align-self-center pl-0">
        <i
          class="fal fa-ruble-sign"
          :class="{ 'error--text': errors.has('form.approved_crdt_sum') }"
        ></i>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-8 align-self-center">
        Одобренная сумма кредита на автомобиль
      </div>
      <div class="col-auto pr-0 text-xs-right">
        <div class="unicus-field-params">
          <unicus-autonumeric-field
            v-model="form.approved_crdt_sum_transport"
            hide-details
            label=" "
            v-validate="getRules('approved_crdt_sum_transport')"
            data-vv-delay="300"
            :class="{
              'has-error': errors.has('form.approved_crdt_sum_transport')
            }"
            data-vv-scope="form"
            data-vv-as="Одобренная сумма кредита на автомобиль"
            name="approved_crdt_sum_transport"
            :disabled="disabled"
          ></unicus-autonumeric-field>
        </div>
      </div>
      <div class="col align-self-center pl-0">
        <i
          class="fal fa-ruble-sign"
          :class="{
            'error--text': errors.has('form.approved_crdt_sum_transport')
          }"
        ></i>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-8 align-self-center">
        Одобренная сумма кредита на страховку
      </div>
      <div class="col-auto pr-0 text-xs-right">
        <div class="unicus-field-params">
          <unicus-autonumeric-field
            v-model="form.approved_crdt_sum_insurance"
            hide-details
            label=" "
            data-vv-scope="form"
            v-validate="getRules('approved_crdt_sum_insurance')"
            data-vv-delay="300"
            :class="{
              'has-error': errors.has('form.approved_crdt_sum_insurance')
            }"
            data-vv-as="Одобренная сумма кредита на страховку"
            name="approved_crdt_sum_insurance"
            :disabled="disabled"
          ></unicus-autonumeric-field>
        </div>
      </div>
      <div class="col align-self-center pl-0">
        <i
          class="fal fa-ruble-sign"
          :class="{
            'error--text': errors.has('form.approved_crdt_sum_insurance')
          }"
        ></i>
      </div>
    </div>

    <div class="row mb-2" v-if="hasSj">
      <div class="col-8 align-self-center">% ставка СЖ Банка</div>
      <div class="col-auto pr-0 text-xs-right">
        <div class="unicus-field-params">
          <unicus-autonumeric-field
            v-model="form.sj_rate"
            hide-details
            label=" "
            v-validate="getRules('sj_rate')"
            data-vv-delay="300"
            :class="{ 'has-error': errors.has('form.sj_rate') }"
            name="sj_rate"
            :disabled="disabled"
            data-vv-scope="form"
            :max-value="99.99"
          />
        </div>
      </div>
      <div class="col align-self-center pl-0">
        <i
          class="fal fa-percent"
          :class="{ 'error--text': errors.has('form.sj_rate') }"
        ></i>
      </div>
    </div>

    <div class="row mb-2" v-if="hasSj">
      <div class="col-8 align-self-center">Сумма СЖ Банка</div>
      <div class="col-auto pr-0 text-xs-right">
        <div class="unicus-field-params">
          <unicus-autonumeric-field
            v-model="form.sj_sum"
            hide-details
            label=" "
            data-vv-scope="form"
            v-validate="getRules('sj_sum')"
            data-vv-delay="300"
            :class="{ 'has-error': errors.has('form.sj_sum') }"
            name="sj_sum"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="col align-self-center pl-0">
        <i
          class="fal fa-ruble-sign"
          :class="{ 'error--text': errors.has('form.sj_sum') }"
        ></i>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-8 align-self-center">Первоначальный взнос</div>
      <div class="col-auto pr-0 text-xs-right">
        <div class="unicus-field-params">
          <unicus-autonumeric-field
            ref="initial_payment"
            v-model="form.initial_payment"
            hide-details
            label=" "
            v-validate="getRules('initial_payment')"
            data-vv-delay="300"
            data-vv-scope="form"
            :class="{ 'has-error': errors.has('form.initial_payment') }"
            data-vv-as="Первоначальный взнос"
            data-vv-name="initial_payment"
            name="initial_payment"
            :disabled="disabled"
          ></unicus-autonumeric-field>
        </div>
      </div>
      <div class="col align-self-center pl-0">
        <i
          class="fal fa-ruble-sign"
          :class="{ 'error--text': errors.has('form.initial_payment') }"
        ></i>
      </div>
    </div>
    <div class="row mb-2" v-show="form.is_residual_payment">
      <div class="col-8 align-self-center">Остаточный платеж</div>
      <div class="col-auto pr-0 text-xs-right">
        <div class="unicus-field-params">
          <unicus-autonumeric-field
            ref="residual_payment"
            v-model="form.residual_payment"
            hide-details
            label=" "
            v-validate="getRules('residual_payment')"
            data-vv-delay="300"
            data-vv-scope="form"
            :class="{ 'has-error': errors.has('form.residual_payment') }"
            data-vv-as="Остаточный платеж"
            name="residual_payment"
            :disabled="disabled"
          ></unicus-autonumeric-field>
        </div>
      </div>
      <div class="col align-self-center pl-0">
        <i
          class="fal fa-ruble-sign"
          :class="{ 'error--text': errors.has('form.residual_payment') }"
        ></i>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-8 align-self-center">Ежемесячный платеж</div>
      <div class="col-auto pr-0 text-xs-right">
        <div class="unicus-field-params">
          <unicus-autonumeric-field
            ref="monthly_payment"
            v-model="form.monthly_payment"
            hide-details
            label=" "
            v-validate="getRules('monthly_payment')"
            data-vv-delay="300"
            data-vv-scope="form"
            :class="{ 'has-error': errors.has('form.monthly_payment') }"
            data-vv-as="Ежемесячный платеж"
            name="monthly_payment"
            :disabled="disabled"
          ></unicus-autonumeric-field>
        </div>
      </div>
      <div class="col align-self-center pl-0">
        <i
          class="fal fa-ruble-sign"
          :class="{ 'error--text': errors.has('form.monthly_payment') }"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParamsBlock",
  inject: ["$validator"],
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasSj: false
    };
  },
  computed: {
    form: function() {
      return this.$store.state.Credit.application.data;
    },
    program_list: function() {
      return this.$store.state.Credit.program_list.data;
    },
    credit_periods: function() {
      return this.$store.state.Credit.credit_periods.data;
    }
  },
  mounted() {
    this.hasSj =
      (parseFloat(this.form.sj_sum) && parseFloat(this.form.sj_sum) > 0) ||
      (parseFloat(this.form.sj_rate) && parseFloat(this.form.sj_rate) > 0);
  },

  methods: {
    getRules: function(field) {
      let rules = "required";

      switch (field) {
        case "residual_payment":
          if (!this.form.is_residual_payment) {
            rules = "";
          } else {
            rules += "|min_value:0";
          }
          break;

        case "program":
        case "credit_period":
        case "rate":
        case "approved_crdt_sum":
        case "approved_crdt_sum_transport":
        case "approved_crdt_sum_insurance":
        case "sj_rate":
        case "sj_sum":
        case "initial_payment":
        case "monthly_payment":
          rules = "required|min_value:0";
          break;
      }

      return rules;
    }
  }
};
</script>

<style lang="stylus" scoped>
.unicus-field-params
    width: 100px;
    .v-text-field
        padding-top: 0px!important;
        margin-top: 0px!important;
</style>
