<template>
  <div class="row align-items-center mt-4 mb-3">
    <div class="col pr-0">
      <v-menu
        v-model="menu"
        lazy
        transition="scale-transition"
        :disabled="disabled"
      >
        <unicus-text-field
          slot="activator"
          v-model="dateText"
          style="width: 230px; user-select: none"
          class="datepicker-text-field"
          :class="{ 'has-error': errors.has('form.offer_valid_period') }"
          hide-details
          outline
          placeholder=" "
          label="Срок действия решения"
          append-icon="event"
          :disabled="menu || disabled"
          readonly
          data-vv-scope="form"
          name="offer_valid_period"
          v-validate="{ required: false }"
        />

        <v-date-picker
          :value="value_date ? value_date.format('YYYY-MM-DD') : ''"
          locale="ru"
          color="primary"
          :min="minDate"
          no-title
          @change="setNewDate"
        />
      </v-menu>
    </div>
    <div class="col-auto pl-2 pr-0  text-sm-center">
      <v-btn
        @click.native="setDateByDays(30)"
        class="ma-0 px-1 btn-day"
        data-days="30"
        flat
        color="primary"
        :disabled="disabled"
      >
        30 дней
      </v-btn>
    </div>
    <div class="col-auto pl-3 pr-0  text-sm-center">
      <v-btn
        @click.native="setDateByDays(60)"
        class="ma-0 px-1 btn-day"
        data-days="60"
        flat
        color="primary"
        :disabled="disabled"
      >
        60 дней
      </v-btn>
    </div>
    <div class="col-auto pl-3 pr-0  text-sm-center">
      <v-btn
        @click.native="setDateByDays(90)"
        class="ma-0 px-1 btn-day"
        data-days="90"
        flat
        color="primary"
        :disabled="disabled"
      >
        90 дней
      </v-btn>
    </div>
    <div class="col-auto pl-3 pr-3  text-sm-center">
      <v-btn
        @click.native="setDateByDays(120)"
        class="ma-0 px-1 btn-day"
        data-days="120"
        flat
        color="primary"
        :disabled="disabled"
      >
        120 дней
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DateBlock2",
  inject: ["$validator"],

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      menu: false,
      dateText: "",
      minDate: moment().format("YYYY-MM-DD"),
      dateStart: undefined
    };
  },

  computed: {
    value_date() {
      if (this.$store.state.Credit.application.data.offer_valid_period) {
        return moment(
          this.$store.state.Credit.application.data.offer_valid_period
        );
      }

      return "";
    },
    last_button() {
      return this.$store.state.Credit.application.last_button;
    }
  },

  mounted() {
    this.dateText = this.value_date ? this.value_date.format("DD-MM-YYYY") : "";
    this.dateStart = this.value_date
      ? this.value_date.format("YYYY-MM-DD")
      : this.minDate;
  },

  methods: {
    setDateByDays: function(days) {
      if (
        !days ||
        !parseInt(days) ||
        !this.dateStart ||
        !moment(this.dateStart).isValid()
      )
        return;
      this.setNewDate(moment(this.dateStart).add(parseInt(days), "d"));
    },
    setNewDate(date) {
      if (!date || !moment(date).isValid()) return;
      date = moment(date);

      this.$store.commit("Credit/setDataApplication", {
        prop: "offer_valid_period",
        value: date.format("YYYY-MM-DD HH:mm:ss")
      });

      this.dateText = date.format("DD-MM-YYYY");
      this.menu = false;
    }
  }
};
</script>

<style scoped>
.btn-day {
  font-size: 13px;
  min-width: auto !important;
  text-transform: none !important;
  font-weight: 600 !important;
}

.datepicker-text-field .v-input__icon--append {
  color: #7795f8;
}
</style>
