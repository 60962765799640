<template>
  <v-container class="unicus-container">
    <div class="row">
      <div class="col-12">
        <v-card class="unicus-btn-border-radius">
          <div class="mx-5 my-3">
            <v-card-title>
              <div class="unicus-card-header">Ошибка 404!</div>
              <v-spacer></v-spacer>
            </v-card-title>
            <hr class="ma-1 font-weight-bold deep-purple lighten-5" />
            <v-card-text>
              Страница не найдена
            </v-card-text>
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Error404"
};
</script>

<style scoped></style>
