<template>
  <div class="row">
    <template v-if="statusViewOrder === statusView">
      <div class="col-6 mb-2">
        <unicus-btn
          block
          large
          color="secondary"
          :disabled="$props.disabled"
          @click="setStatusRejectApplicationByHash(options)"
        >
          <v-icon left dark>thumb_down</v-icon>
          <span>Отказать в<br />согласовании</span>
        </unicus-btn>
      </div>

      <div class="col-6 mb-2">
        <unicus-btn
          block
          large
          color="primary"
          :disabled="$props.disabled"
          @click="setStatusApproveApplicationByHash(options)"
        >
          <v-icon left dark>thumb_up</v-icon>
          <span>Согласовать без<br />изменений</span>
        </unicus-btn>
      </div>

      <div class="offset-6 col-6 mb-2">
        <unicus-btn
          block
          large
          color="primary"
          :disabled="$props.disabled"
          @click="handleEdit"
        >
          <span>Внести изменения</span>
        </unicus-btn>
      </div>
    </template>

    <template v-if="statusViewOrder === statusEdit">
      <div class="col-6 mb-2">
        <unicus-btn
          block
          large
          color="primary"
          outline
          :disabled="$props.disabled"
          @click="handleRevertEdit"
        >
          <span>Назад</span>
        </unicus-btn>
      </div>

      <div class="col-6 mb-2">
        <unicus-btn
          block
          large
          color="primary"
          :disabled="$props.disabled"
          @click="setStatusApproveWithConditionApplicationByHash(options)"
        >
          <span>Согласовать с<br />изменениями</span>
        </unicus-btn>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {
  STATUS_VIEW_ORDER_EDIT,
  STATUS_VIEW_ORDER_VIEW
} from "@/module/insurance/types";
import ModelApplicationData from "@/module/insurance/models/ModelApplicationData";

export default {
  name: "ActionsBlock",

  props: {
    hash: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState("Insurance/Kasko", {
      statusViewOrder: state => state.statusViewOrder,
      application: state => state.application.data,
      dataApproved: state => state.application.data.dataApproved
    }),
    options() {
      const options = {
        hash: this.$props.hash
      };

      if (this.statusViewOrder === this.statusEdit) {
        options["approvedCost"] = this.dataApproved.getCostNumber();
        options["approvedPremium"] = this.dataApproved.getPremiumNumber();
        options["approvedDiscountSk"] = this.dataApproved.getDiscountSkNumber();
        options[
          "approvedDiscountRacm"
        ] = this.dataApproved.getDiscountRacmNumber();
      }

      return options;
    }
  },

  data: () => ({
    statusView: STATUS_VIEW_ORDER_VIEW,
    statusEdit: STATUS_VIEW_ORDER_EDIT,
    cashDataApproved: null
  }),

  methods: {
    ...mapActions("Insurance/Kasko", [
      "setStatusRejectApplicationByHash",
      "setStatusApproveApplicationByHash",
      "setStatusApproveWithConditionApplicationByHash"
    ]),
    ...mapMutations("Insurance/Kasko", [
      "UPDATE_STATUS_VIEW_ORDER",
      "UPDATE_DATA"
    ]),
    handleEdit() {
      this.UPDATE_STATUS_VIEW_ORDER(this.statusEdit);
      this.cashDataApproved = new ModelApplicationData(this.dataApproved);

      if (this.application.dataApproved.hasData()) return;

      this.UPDATE_DATA({
        ...this.application,
        dataApproved: { ...this.application.dataRequested }
      });
    },
    handleRevertEdit() {
      this.UPDATE_STATUS_VIEW_ORDER(this.statusView);
      this.UPDATE_DATA({
        ...this.application,
        dataApproved: { ...this.cashDataApproved }
      });
    }
  }
};
</script>
