<template>
  <v-container class="unicus-container">
    <div class="row">
      <div class="col-12">
        <unicus-card v-if="init">
          <div v-if="initError">
            <v-card-title>
              <div class="unicus-card-header">Ошибка!</div>
            </v-card-title>
            <v-card-text>
              {{ initError }}
            </v-card-text>
          </div>
          <div v-else class="mx-5 my-3">
            <v-card-title class="pr-0">
              <div class="row pr-0" style="width: 100%;">
                <div class="col-auto">
                  <div class="unicus-card-header">Решение банка</div>
                  <span v-if="form.resolution">
                    <span class="unicus-badge mr-1">{{ statusText }}</span>
                    <span class="unicus-time">{{ updatedDate }}</span>
                  </span>
                </div>
                <div class="col"></div>
                <div class="col-auto text-sm-right pr-0 pt-3">
                  <span>
                    <span v-if="form.client_code === 'AVILON'">
                      ГК Авилон
                    </span>
                    <span v-if="form.client_code === 'ROLF'">
                      Рольф
                    </span>
                    <br />
                    Заявка #{{ form.id }}
                  </span>
                </div>
              </div>
            </v-card-title>
            <v-card-text class="pt-0">
              <div class="unicus-hr-header mb-4"></div>

              <params-block :disabled="has_disabled_form" />
              <insurance-block />
              <date-block :disabled="has_disabled_form" />

              <div class="row mb-4">
                <div class="col-12">
                  <unicus-textarea
                    v-model="form.comment"
                    outline
                    placeholder=" "
                    label="Комментарий"
                    value="Клиент должен приложить военный билет"
                    hide-details
                    v-validate="getRules('comment')"
                    data-vv-delay="300"
                    data-vv-scope="form"
                    :class="{ 'has-error': errors.has('form.comment') }"
                    data-vv-as="Программа кредитования"
                    name="comment"
                    :disabled="has_disabled_form"
                  />
                </div>
              </div>

              <files-block :hash="hash" class="mb-5" />

              <button-block :disabled="has_disabled_form" />
            </v-card-text>

            <v-dialog v-model="has_disabled_form" persistent width="300">
              <v-card color="primary" dark>
                <v-card-text>
                  Информация по заявке была обновлена, просьба перейти к новым
                  данным.
                </v-card-text>
                <v-card-actions>
                  <v-spacer />

                  <v-btn flat="flat" @click="redirectHash(new_hash)">
                    Перейти
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </unicus-card>
        <div v-else class="text-center ma-5 pa-5">
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          />
          <p class="pt-4 unicus-title">Инициализация...</p>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import DateBlock from "../components/date-block.vue";
import ButtonBlock from "../components/button-block.vue";
import InsuranceBlock from "../components/insurance-block";
import ParamsBlock from "../components/params-block";
import FilesBlock from "../components/files-block";

export default {
  name: "FormForBankEmployee",
  components: {
    FilesBlock,
    ParamsBlock,
    DateBlock,
    ButtonBlock,
    InsuranceBlock
  },
  inject: ["$validator"],
  props: {
    hash: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      init: false,
      initError: ""
    };
  },
  computed: {
    form: function() {
      return this.$store.state.Credit.application.data;
    },
    getRemovedFields: function() {
      return this.$store.getters["Credit/getRemovedFields"];
    },
    issetRemovedFields: function() {
      return this.$store.getters["Credit/issetRemovedFields"];
    },
    issetChangedFields: function() {
      return this.$store.getters["Credit/issetChangedFields"];
    },
    statusText: function() {
      return this.$store.getters["Credit/getStatusName"](this.form.resolution);
    },
    updatedDate: function() {
      return this.$store.getters["Credit/formatDate"](this.form.updated_date);
    },
    temp_type: function() {
      return this.$store.state.Credit.application.last_button;
    },
    new_hash() {
      return this.$store.state.Credit.new_hash;
    },
    has_disabled_form() {
      return this.new_hash !== "";
    },
    showError: function() {
      return this.$store.state.Errors.showError;
    }
  },
  mounted: function() {
    this.initDataHash(this.$props.hash);
  },
  methods: {
    async initDataHash(hash) {
      if (!hash) return;

      await this.$store.dispatch("Credit/ReadApplication", hash);
      await this.$store.dispatch("Credit/ProgramsList", hash);
      await this.$store.dispatch("Credit/CreditPeriods");

      this.init = true;
    },
    getRules: function(field) {
      let rules = "";

      switch (field) {
        case "comment":
          if (this.temp_type === "approve_with_condition") {
            rules = "required";
          }
          break;
      }

      return rules;
    },
    redirectHash(hash) {
      if (!hash) return;
      this.$router.push({
        name: "form-for-bank-employee",
        params: { hash: hash }
      });
      this.init = false;
      this.initDataHash(hash);
    }
  }
};
</script>
