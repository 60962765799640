import Api from "@/plugins/api";
import {
  RESPONSE_STATUS_SUCCESS,
  STATUS_VIEW_ORDER_VIEW
} from "@/module/insurance/types";

function selectApplicationByHash(context, hash) {
  const { commit } = context;
  commit("UPDATE_LOADING", true);

  const promise = Api(axios => axios.get(`insurance/kasko/${hash}`));
  promise.then(({ data, status }) => {
    if (status !== RESPONSE_STATUS_SUCCESS) return;

    commit("UPDATE_DATA", {
      ...data,
      dataRequested: {
        cost: data?.cost,
        premium: data?.premium,
        discountRacm: data?.discountRacm,
        discountSk: data?.discountSk
      },
      dataApproved: {
        cost: data?.approvedCost,
        premium: data?.approvedPremium,
        discountRacm: data?.approvedDiscountRacm,
        discountSk: data?.approvedDiscountSk
      }
    });
  });

  promise.finally(() => commit("UPDATE_LOADING", false));

  return promise;
}

function setStatusRejectApplicationByHash(context, payload) {
  const { commit } = context;
  const { hash } = payload;
  if (!hash) return;
  commit("UPDATE_LOADING", true);

  const promise = Api(axios =>
    axios.patch(`insurance/kasko/${hash}/reject`, payload)
  );
  promise.then(({ data, status }) => {
    if (status !== RESPONSE_STATUS_SUCCESS) return;

    commit("UPDATE_DATA", {
      ...data,
      dataRequested: {
        cost: data?.cost,
        premium: data?.premium,
        discountRacm: data?.discountRacm,
        discountSk: data?.discountSk
      },
      dataApproved: {
        cost: data?.approvedCost,
        premium: data?.approvedPremium,
        discountRacm: data?.approvedDiscountRacm,
        discountSk: data?.approvedDiscountSk
      }
    });
  });

  promise.finally(() => commit("UPDATE_LOADING", false));

  return promise;
}

function setStatusApproveApplicationByHash(context, payload) {
  const { commit } = context;
  const { hash } = payload;
  if (!hash) return;
  commit("UPDATE_LOADING", true);

  const promise = Api(axios =>
    axios.patch(`insurance/kasko/${hash}/approve`, payload)
  );
  promise.then(({ data, status }) => {
    if (status !== RESPONSE_STATUS_SUCCESS) return;

    commit("UPDATE_DATA", {
      ...data,
      dataRequested: {
        cost: data?.cost,
        premium: data?.premium,
        discountRacm: data?.discountRacm,
        discountSk: data?.discountSk
      },
      dataApproved: {
        cost: data?.approvedCost,
        premium: data?.approvedPremium,
        discountRacm: data?.approvedDiscountRacm,
        discountSk: data?.approvedDiscountSk
      }
    });
  });

  promise.finally(() => commit("UPDATE_LOADING", false));

  return promise;
}

function setStatusApproveWithConditionApplicationByHash(context, payload) {
  const { commit } = context;
  const { hash } = payload;
  if (!hash) return;
  commit("UPDATE_LOADING", true);
  commit("UPDATE_STATUS_VIEW_ORDER", STATUS_VIEW_ORDER_VIEW);

  const promise = Api(axios =>
    axios.patch(`insurance/kasko/${hash}/approve-with-condition`, payload)
  );
  promise.then(({ data, status }) => {
    if (status !== RESPONSE_STATUS_SUCCESS) return;

    commit("UPDATE_DATA", {
      ...data,
      dataRequested: {
        cost: data?.cost,
        premium: data?.premium,
        discountRacm: data?.discountRacm,
        discountSk: data?.discountSk
      },
      dataApproved: {
        cost: data?.approvedCost,
        premium: data?.approvedPremium,
        discountRacm: data?.approvedDiscountRacm,
        discountSk: data?.approvedDiscountSk
      }
    });
  });

  promise.finally(() => commit("UPDATE_LOADING", false));

  return promise;
}

const actionsApplication = {
  selectApplicationByHash,
  setStatusRejectApplicationByHash,
  setStatusApproveApplicationByHash,
  setStatusApproveWithConditionApplicationByHash
};

export default actionsApplication;
