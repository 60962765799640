export class CreditFile {
  constructor() {
    this.id = null;
    this.name = null;
    this.date = null;
    this.type = null;
    this.path = null;
    this.is_deleted = false;
  }

  getMapProps() {
    return {
      documentName: "name",
      createdAt: "date",
      documentContentType: "type",
      isDeleted: "is_deleted"
    };
  }

  getPropNumbers() {
    return ["id"];
  }

  getPropDates() {
    return ["date", "date_delete"];
  }

  getPropBooleans() {
    return ["is_deleted"];
  }

  setProps(data = {}) {
    const map = this.getMapProps();
    const numbers = this.getPropNumbers();
    const dates = this.getPropDates();
    const booleans = this.getPropBooleans();

    for (let key in data) {
      let prop = undefined;

      if (this.hasOwnProperty(key)) prop = key;
      else if (map.hasOwnProperty(key)) prop = map[key];
      else continue;

      if (numbers.includes(prop)) {
        this[prop] = parseFloat(data[key]) || 0;
      } else if (dates.includes(prop)) {
        this[prop] = new Date(data[key]);
      } else if (booleans.includes(prop)) {
        this[prop] =
          data[key] === true || data[key] === "true" || data[key] === 1;
      } else {
        this[prop] = data[key];
      }
    }
  }
}

export default CreditFile;
