<template>
  <transition name="fade" leave-active-class="">
    <router-view></router-view>
  </transition>
</template>

<script>
export default {
  name: "AccountLayout"
};
</script>

<style scoped></style>
