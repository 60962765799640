import ModelStatus from "@/module/insurance/models/ModelStatus";

export const STATUSES = [
  new ModelStatus("Новый", "new"),
  new ModelStatus("Отказ", "reject"),
  new ModelStatus("Согласовано без изменений", "approve"),
  new ModelStatus("Согласовано с изменениями", "approve_with_condition")
];

export const STATUS_VIEW_ORDER_VIEW = "view";
export const STATUS_VIEW_ORDER_EDIT = "edit";

export const RESPONSE_STATUS_SUCCESS = 200;
