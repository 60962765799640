import InsuranceLayout from "@/module/insurance/layouts/insurance-layout";
import InsuranceKasko from "@/module/insurance/controllers/InsuranceKasko";

const insuranceRoutes = {
  path: "insurance",
  name: "insurance.layout",
  props: true,
  component: InsuranceLayout,
  children: [
    {
      path: "application/:hash",
      name: "insurance.controller.kasko",
      component: InsuranceKasko,
      props: true
    }
  ]
};

export default insuranceRoutes;
