export default class Insurance {
  id;
  product;
  company;
  amount;
  include_in_credit;
  issued_in_bank;

  constructor() {
    this.id = null;
    this.product = null;
    this.company = null;
    this.amount = null;
    this.include_in_credit = null;
    this.issued_in_bank = null;
  }
}
