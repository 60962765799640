import Vue from "vue";
import Vuex from "vuex";
import ErrorsStore from "../module/errors/store";
import CreditStore from "../module/credit/store";
import InsuranceStore from "../module/insurance/store";
import AccountStore from "../module/account/store";

Vue.use(Vuex);

const modules = {
  Credit: CreditStore,
  Insurance: InsuranceStore,
  Errors: ErrorsStore,
  Account: AccountStore
};

export default new Vuex.Store({
  modules
});
