import isUndefined from "lodash/isUndefined";
import parse from "@/utilites/parse";
import ModelApplicationData from "@/module/insurance/models/ModelApplicationData";
import { STATUSES } from "@/module/insurance/types";

class ModelApplication {
  /**
   * Индификатор расчета СК
   * @type {string}
   */
  extId = "";

  /**
   * Статус
   * - new: новый
   * - reject: отказ
   * - approve: согласовано без изменений
   * - approve_with_condition: согласовано с изменениями
   * @type {null, string}
   */
  status = null;

  /**
   * Название программы
   * @type {null, string}
   */
  programName = null;

  /**
   * Комментарий
   * @type {string}
   */
  comment = "";

  /**
   * Дата создания
   * @type {null, Date}
   */
  createdAt = null;

  /**
   * Дата обновления
   * @type {null, Date}
   */
  updatedAt = null;

  /**
   * Запрошено
   * @type {ModelApplicationData}
   */
  dataRequested = new ModelApplicationData();

  /**
   * Одобрено
   * @type {ModelApplicationData}
   */
  dataApproved = new ModelApplicationData();

  constructor(data = {}) {
    if (!data) return;

    const {
      extId,
      status,
      programName,
      comment,
      createdAt,
      updatedAt,
      dataRequested,
      dataApproved
    } = data || {};

    if (!isUndefined(extId)) {
      this.extId = parse.parseString(extId);
    }

    if (!isUndefined(status)) {
      this.setStatus(status);
    }

    if (!isUndefined(programName)) {
      this.programName = parse.parseString(programName);
    }

    if (!isUndefined(comment)) {
      this.comment = parse.parseString(comment);
    }

    if (!isUndefined(createdAt)) {
      this.createdAt = parse.parseDate(createdAt);
    }

    if (!isUndefined(updatedAt)) {
      this.updatedAt = parse.parseDate(updatedAt);
    }

    if (!isUndefined(dataRequested)) {
      this.dataRequested = new ModelApplicationData(dataRequested);
    }

    if (!isUndefined(dataApproved)) {
      this.dataApproved = new ModelApplicationData(dataApproved);
    }
  }

  setStatus(status) {
    const statusList = STATUSES.map(v => v.code);
    if (statusList.includes(status)) this.status = status;
  }
}

export default ModelApplication;
