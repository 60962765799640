import kaskoStore from "./modules/Kasko";

const insuranceStore = {
  namespaced: true,
  modules: {
    Kasko: kaskoStore
  },
  state: {}
};

export default insuranceStore;
