<template>
  <span style="white-space: nowrap">
    <template v-if="$props.value">
      {{ $props.value | pretty_numeric }}
    </template>
    <template v-else>0 </template>
    <i class="fal fa-percent" />
  </span>
</template>

<script>
export default {
  name: "UnitPercent",

  props: {
    value: {
      default: 0,
      required: true,
      validator: prop => typeof prop === "number" || prop === null
    }
  }
};
</script>
