class ModelStatus {
  /**
   * Название статуса
   * @type {string}
   */
  name = "";

  /**
   * Код статуса
   * @type {string}
   */
  code = "";

  /**
   * Стиль статуса
   * @type {string}
   */
  styleVariant = "success";

  constructor(name, code, styleVariant) {
    this.name = name || "";
    this.code = code || "";
    this.setStyleVariant(styleVariant);
  }

  setStyleVariant(styleVariant) {
    const styleVariants = ["success"];

    if (styleVariants.includes(styleVariant)) {
      this.styleVariant = styleVariant;
    }
  }
}

export default ModelStatus;
