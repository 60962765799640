import {
  STATUS_VIEW_ORDER_EDIT,
  STATUS_VIEW_ORDER_VIEW
} from "@/module/insurance/types";

function UPDATE_STATUS_VIEW_ORDER(state, status = STATUS_VIEW_ORDER_VIEW) {
  const statuses = [STATUS_VIEW_ORDER_VIEW, STATUS_VIEW_ORDER_EDIT];

  if (!statuses.includes(status)) return;
  state.statusViewOrder = status;
}

const mutations = {
  UPDATE_STATUS_VIEW_ORDER
};

export default mutations;
