import Insurance from "./insurance";

export default class BankSolution {
  constructor() {
    this.id = null;
    this.client_code = null;
    this.bank_id = null;
    this.program = null;
    this.term = null;
    this.rate = null;
    this.approved_crdt_sum = null;
    this.approved_crdt_sum_transport = null;
    this.approved_crdt_sum_insurance = null;
    this.approved_transport_cost = null;
    this.initial_payment = null;
    this.is_residual_payment = 0;
    this.residual_payment = null;
    this.monthly_payment = null;
    this.date_solution = "";
    this.resolution = "";
    this.comment = "";
    this.hash = "";
    this.department_id = null;
    this.department_code = null;
    this.updated_date = null;
    this.offer_valid_period = null;
    this.insurances = [];
    this.transport = null;
    this.sj_rate = undefined;
    this.sj_sum = undefined;
  }

  getMapApp() {
    return {
      id: "id",
      client_code: "systemType",
      hash: "hash",
      program: "programId",
      bank_id: "bankId",
      department_id: "departmentId",
      department_code: "departmentCode",
      credit_period: "creditPeriod",
      rate: "rate",
      approved_crdt_sum: "approvedCrdtSum",
      approved_crdt_sum_transport: "approvedCrdtSumTransport",
      approved_crdt_sum_insurance: "approvedCrdtSumInsurance",
      initial_payment: "feeCash",
      residual_payment: "residualPayment",
      monthly_payment: "monthlyPayment",
      resolution: "status",
      date_solution: "decisionDate",
      comment: "commentary",
      updated_date: "updatedDate",
      transport: "transport",
      sj_rate: "sjRate",
      sj_sum: "sjSum",
      offer_valid_period: "offerValidPeriod",
      approved_transport_cost: "approvedTransportCost"
    };
  }

  getMapInsurance() {
    return {
      id: "id",
      product: "productId",
      company: "departmentId",
      amount: "cost",
      include_in_credit: "includeInCredit",
      issued_in_bank: "issuedInBank"
    };
  }

  setByApi(app) {
    const mapApp = this.getMapApp();

    for (let key in mapApp) {
      this[key] = app[mapApp[key]];
    }

    if (app["residualPayment"]) {
      this.is_residual_payment = 1;
    } else {
      this.residual_payment = 0;
    }

    const mapInsurance = this.getMapInsurance();

    if (app.insurances && app.insurances.length > 0) {
      for (var i = 0; i < app.insurances.length; i++) {
        var ins = new Insurance();

        for (let key in mapInsurance) {
          ins[key] = app.insurances[i][mapInsurance[key]];
        }

        this.insurances.push(ins);
      }
    }
  }

  getForApi() {
    let app = {};
    const mapApp = this.getMapApp();

    for (let key in mapApp) {
      if (this.getPropNumbers().includes(key)) {
        app[mapApp[key]] =
          parseFloat(this[key]) >= 0 ? parseFloat(this[key]) : null;
        continue;
      }

      app[mapApp[key]] = this[key] || null;
    }

    return app;
  }

  getPropNumbers() {
    return [
      "approved_crdt_sum",
      "approved_crdt_sum_insurance",
      "approved_crdt_sum_transport",
      "approved_transport_cost",
      "bank_id",
      "credit_period",
      "department_id",
      "initial_payment",
      "id",
      "monthly_payment",
      "program",
      "rate",
      "residual_payment",
      "sj_rate",
      "sj_sum"
    ];
  }
}
