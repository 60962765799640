import ModelStateApplication from "./ModelStateApplication";
import { STATUS_VIEW_ORDER_VIEW, STATUSES } from "@/module/insurance/types";

class ModelState {
  /**
   * Данные по заявке
   * @type {ModelStateApplication}
   */
  application = new ModelStateApplication();

  /**
   * Список статусов
   */
  statuses = [...STATUSES];

  /**
   * Статус отображения заявки
   * - view: просмотр
   * - edit: редактирование
   * @type {string}
   */
  statusViewOrder = STATUS_VIEW_ORDER_VIEW;
}

export default ModelState;
