import BankSolution from "../models/bank-solution";

const state = {
  new_hash: "",
  application: {
    loading: false,
    last_button: "",
    data: new BankSolution(),
    response: new BankSolution()
  },
  program_list: {
    loading: false,
    data: []
  },
  credit_periods: {
    loading: false,
    data: []
  },
  save_application: {
    loading: false,
    request: undefined,
    response: undefined
  },
  files: []
};

export default state;
