import ModelApplication from "@/module/insurance/models/ModelApplication";

function UPDATE_LOADING(state, loading = false) {
  state.application.loading = loading === true;
}

function UPDATE_DATA(state, data = null) {
  state.application.data = new ModelApplication(data);
}

const mutationsApplication = {
  UPDATE_LOADING,
  UPDATE_DATA
};

export default mutationsApplication;
