import ModelState from "../../../models/ModelState";
import mutations from "./mutations/mutations";
import mutationsApplication from "./mutations/application";
import actions from "./actions/actions";
import actionsApplication from "./actions/application";
import getters from "./getters/getters";

const kaskoMutations = {
  ...mutations,
  ...mutationsApplication
};

const kaskoActions = {
  ...actions,
  ...actionsApplication
};

const kaskoGetters = {
  ...getters
};

const kaskoStore = {
  namespaced: true,
  state: new ModelState(),
  mutations: kaskoMutations,
  actions: kaskoActions,
  getters: kaskoGetters
};

export default kaskoStore;
