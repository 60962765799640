import CreditLayout from "./layouts/credit-layout.vue";
import FormBankEmployee from "./controllers/form-for-bank-employee.vue";

export default {
  path: "credit",
  name: "credit.layout",
  props: true,
  component: CreditLayout,
  children: [
    {
      path: "application/:hash",
      name: "form-for-bank-employee",
      props: true,
      component: FormBankEmployee
    }
  ]
};
