import moment from "moment";

export default {
  getHaystack(state) {
    return function(withoutSelect) {
      let keys = {
        rate: "Процентная ставка",
        approved_crdt_sum: "Стоимость ТС",
        approved_transport_cost: "Одобренная сумма кредита",
        approved_crdt_sum_transport: "Одобренная сумма кредита на автомобиль",
        approved_crdt_sum_insurance: "Одобренная сумма кредита на страховку",
        initial_payment: "Первоначальный взнос",
        monthly_payment: "Ежемесячный платеж",
        sj_sum: "Сумма СЖ Банка",
        sj_rate: "% ставка СЖ Банка"
      };

      if (
        state.application.data &&
        state.application.data.is_residual_payment
      ) {
        keys["residual_payment"] = "Остаточный платеж";
      }

      if (!withoutSelect) {
        keys["program"] = "Программа";
        keys["credit_period"] = "Срок кредита";
      }

      return keys;
    };
  },
  getRemovedFields(state, getters) {
    let keys = getters.getHaystack(true);
    let arr_removed = [];

    for (let k in keys) {
      if (
        (!state.application.data[k] ||
          String(state.application.data[k]).trim().length == 0) &&
        state.application.data[k] != state.application.response[k]
      ) {
        arr_removed.push(k);
      }
    }

    return arr_removed;
  },
  issetRemovedFields(state, getters) {
    let haystack = getters.getHaystack(true);

    let keys = [];

    for (let key in haystack) {
      keys.push(key);
    }

    let rkeys = getters.getRemovedFields;
    return rkeys.length > 0 && rkeys.length === keys.length;
  },
  issetChangedFields(state, getters) {
    let keys = getters.getHaystack();
    let is_changed = false;

    for (let k in keys) {
      if (
        String(state.application.data[k]) !==
        String(state.application.response[k])
      ) {
        is_changed = true;
        break;
      }
    }

    return is_changed;
  },
  statusMap() {
    return {
      cancel: "Отказано",
      approve: "Одобрено",
      clarify: "На уточнении",
      bank_inquiry: "На уточнении",
      approve_with_condition: "Одобрено с условием"
    };
  },
  getStatusName: function(state, getters) {
    return function(status) {
      let map = getters.statusMap;
      return map[status];
    };
  },
  formatDate() {
    return function(date) {
      return moment(date).format("DD.MM.YYYY / HH:mm");
    };
  },

  getInsuranceAdditionalConditionName() {
    return (include_in_credit, issued_in_bank) => {
      if (include_in_credit == null || issued_in_bank == null) {
        return "";
      }

      const ADDITIONAL_CONDITIONS = {
        "00": "За наличные",
        "10": "В кредит",
        "11": "В банке"
      };

      return ADDITIONAL_CONDITIONS[
        String(include_in_credit) + String(issued_in_bank)
      ];
    };
  }
};
