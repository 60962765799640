<template>
  <v-container class="unicus-container">
    <div v-if="application.loading" class="text-center ma-5 pa-5">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      />
      <p class="pt-4 unicus-title">Инициализация...</p>
    </div>

    <unicus-card v-else-if="!application.data.extId">
      <v-card-text>
        <p class="unicus-title text-xs-center mb-0">
          Не удалось получить данные по заявке!
        </p>
      </v-card-text>
    </unicus-card>

    <unicus-card v-else>
      <div class="mx-5 my-3">
        <v-card-title class="pr-0" style="position:relative;">
          <div class="unicus-card-header">Согласование условий расчета</div>

          <span v-if="application.data.status">
            <span class="unicus-badge mr-1">{{ statusText }}</span>
            <span class="unicus-time">{{ updateAtText }}</span>
          </span>

          <span
            class="text-sm-right"
            style="position: absolute; right: 0; top: -10px;"
          >
            <small class="color-label">Идентификатор расчета СК:</small>
            <br />{{ application.data.extId }}
          </span>
        </v-card-title>

        <v-card-text class="pt-0">
          <div class="unicus-hr-header mb-4"></div>

          <div v-show="application.data.programName" class="mb-3">
            <p class="unicus-title mb-2">Программа</p>
            <span>{{ application.data.programName }}</span>
          </div>

          <div class="mb-4">
            <p class="unicus-title mb-3">Параметры одобрения</p>
            <ParamsBlock class="pb-4" />
          </div>

          <template>
            <p class="text-xs-center color-label">
              <span v-if="isViewActions">
                Нажмите кнопку соответствующую Вашему решению
              </span>
              <span v-else>
                Заявка по согласованию данного расчета уже выполнена
              </span>
            </p>

            <ActionsBlock :hash="$props.hash" :disabled="!isViewActions" />
          </template>
        </v-card-text>
      </div>
    </unicus-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import ParamsBlock from "@/module/insurance/components/ParamsBlock";
import ActionsBlock from "@/module/insurance/components/ActionsBlock";

export default {
  name: "InsuranceKasko",
  components: { ActionsBlock, ParamsBlock },
  inject: ["$validator"],

  props: {
    hash: {
      type: String,
      required: true,
      default: ""
    }
  },

  computed: {
    ...mapState("Insurance/Kasko", {
      application: state => state.application
    }),
    status: self => self.getStatusByCode()(self.application.data.status),
    statusText: self => self.status?.name || "",
    isViewActions: self => self.status.code === "new",
    updateAtText() {
      const { updateAt } = this.application.data;
      return moment(updateAt).format("DD.MM.YYYY / HH:mm");
    }
  },

  mounted() {
    this.selectApplicationByHash(this.$props.hash);
  },

  methods: {
    ...mapActions("Insurance/Kasko", ["selectApplicationByHash"]),
    ...mapGetters("Insurance/Kasko", ["getStatusByCode"])
  }
};
</script>
