<template>
  <div class="row">
    <div class="col-6">
      <v-tooltip
        open-delay="1000"
        top
        content-class="unicus-tooltip tooltip-btn"
      >
        <unicus-btn
          slot="activator"
          :disabled="disabled"
          :loading="save_application.loading"
          @click="submitResolution('cancel')"
          block
          large
          color="secondary"
          class="btn-solution"
          data-type="cancel"
        >
          <v-icon left dark>thumb_down</v-icon>
          <span>
            Отказать
          </span>
        </unicus-btn>
        <span
          >При нажатии на кнопку "Отказать" пользователю будет отправлена
          информация об отказе в кредите</span
        >
      </v-tooltip>
    </div>
    <div class="col-6">
      <v-tooltip
        open-delay="1000"
        top
        content-class="unicus-tooltip tooltip-btn"
      >
        <unicus-btn
          slot="activator"
          :disabled="issetChangedFields || disabled"
          :loading="save_application.loading"
          @click="submitResolution('approve')"
          block
          large
          color="primary"
          class="btn-solution"
          data-type="approve"
        >
          <v-icon left dark>thumb_up </v-icon>
          Одобрить
        </unicus-btn>
        <span>При нажатии на кнопку кредитная заявка будет одобрена</span>
      </v-tooltip>
    </div>
    <div class="col-6">
      <v-tooltip
        open-delay="1000"
        top
        content-class="unicus-tooltip tooltip-btn"
      >
        <unicus-btn
          slot="activator"
          :disabled="disabled"
          :loading="save_application.loading"
          @click="submitResolution('clarify')"
          block
          outline
          large
          color="primary"
          class="btn-solution"
          data-type="clarify"
        >
          <span>
            Уточнить
          </span>
        </unicus-btn>
        <span
          >Используется при необходимости запросить дополнительные данные о
          клиенте, без одобрения или отказа в кредите</span
        >
      </v-tooltip>
    </div>
    <div class="col-6">
      <v-tooltip
        open-delay="1000"
        top
        content-class="unicus-tooltip tooltip-btn"
      >
        <unicus-btn
          slot="activator"
          :disabled="!issetChangedFields || issetRemovedFields || disabled"
          :loading="save_application.loading"
          @click="submitResolution('approve_with_condition')"
          block
          outline
          large
          color="primary"
          class="btn-solution"
          data-type="approve_with_condition"
        >
          Одобрить с условием
        </unicus-btn>
        <span
          >Кредитная заявка считается одобренной, но на сделку клиенту
          необходимо предоставить запрошенную банком информацию</span
        >
      </v-tooltip>
    </div>
    <v-dialog v-model="saveErrorModal" width="500">
      <v-card class="save-error-modal">
        <v-card-title dark class="headline red white--text">
          Ошибка!
        </v-card-title>

        <v-card-text>
          {{ saveError }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <unicus-btn color="primary" flat @click="saveErrorModal = false">
            OK
          </unicus-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showConfirmWithRemovedFields" width="500">
      <v-card class="confirm-with-removed-fields">
        <v-card-title class="headline">Подтверждение</v-card-title>

        <v-card-text>
          Внимание следующие поля не заполнены: {{ removedLabels }}. <br />Вы
          уверены, что хотите сохранить?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            :loading="save_application.loading"
            @click="sendData"
          >
            Да
          </v-btn>
          <v-btn
            color="primary"
            :loading="save_application.loading"
            @click="showConfirmWithRemovedFields = false"
          >
            Нет
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSuccessSave" :timeout="3000" :top="true">
      Заявка успешно сохранена
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "ButtonBlock",
  inject: ["$validator"],

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      saveErrorModal: false,
      saveError: "",
      removedLabels: "",
      showConfirmWithRemovedFields: false,
      showSuccessSave: false
    };
  },
  computed: {
    form: function() {
      return this.$store.state.Credit.application.data;
    },
    save_application: function() {
      return this.$store.state.Credit.save_application;
    },
    getRemovedFields: function() {
      return this.$store.getters["Credit/getRemovedFields"];
    },
    issetRemovedFields: function() {
      return this.$store.getters["Credit/issetRemovedFields"];
    },
    issetChangedFields: function() {
      return this.$store.getters["Credit/issetChangedFields"];
    },
    temp_type: function() {
      return this.$store.state.Credit.application.last_button;
    }
  },
  methods: {
    async submitResolution(type) {
      const fieldOfferValidPeriod = this.$validator.fields.find({
        name: "offer_valid_period",
        scope: "form"
      });

      const fieldComment = this.$validator.fields.find({
        name: "comment",
        scope: "form"
      });

      fieldOfferValidPeriod.update({ rules: { required: false } });
      fieldComment.update({ rules: { required: false } });

      if (type === "approve" || type === "approve_with_condition") {
        fieldOfferValidPeriod.update({ rules: { required: true } });
      }

      const valid = await this.$validator.validateAll("form");
      if (!type || !valid) return false;

      if (type === "approve_with_condition" && !this.form.comment) {
        fieldComment.update({ rules: { required: true } });
      }

      this.$store.commit("Credit/setLastButton", type);
      this.$store.commit("Credit/setDataApplication", {
        prop: "date_solution",
        value: moment().format("YYYY-MM-DD HH:mm:ss")
      });

      let rKeys = this.getRemovedFields;
      if (rKeys && rKeys.length > 0) {
        this.showConfirmBeforeSend(rKeys);
      } else {
        this.sendData();
      }
    },
    showConfirmBeforeSend: function(rkeys) {
      var keys = this.getHaystack();

      var label_arr = [];

      for (var k in rkeys) {
        var kk = rkeys[k];
        if (keys[kk]) {
          label_arr.push(keys[kk]);
        }
      }

      this.removedLabels = label_arr.join(", ");
      this.showConfirmWithRemovedFields = true;
    },
    sendData: function() {
      var _this = this;
      _this.form.resolution = this.temp_type;
      _this.SaveBankApplication();
    },
    SaveBankApplication: function() {
      var _this = this;

      if (
        _this.form.resolution == "approve_with_condition" &&
        !_this.form.comment
      ) {
        _this.$store.dispatch("Errors/addError", "Заполните комментарий");
        return;
      }

      var promise = _this.$store.dispatch(
        "Credit/SaveBankApplication",
        _this.form
      );

      promise.then(function() {
        _this.$store.commit("Credit/setLastButton", "");
        _this.removedLabels = "";
        _this.showConfirmWithRemovedFields = false;
        _this.showSuccessSave = true;
      });
    },
    getHaystack: function(withoutSelect) {
      return this.$store.getters["Credit/getHaystack"](withoutSelect);
    }
  }
};
</script>
