import state from "./store/state";
import mutations from "./store/mutations";
import getters from "./store/getters";
import actions from "./store/actions";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions
};
