import Vue from "vue";
import Vuetify from "vuetify";
import FileUpload from "vue-upload-component";
import ru from "vuetify/es5/locale/ru";

Vue.component("file-upload", FileUpload);

Vue.use(Vuetify, {
  theme: {
    primary: "#7795f8",
    secondary: "#F06292",
    accent: "#2951fa",
    error: "#FF5252",
    info: "#ecf4f7",
    success: "#4CAF50",
    warning: "#FFC107"
  },
  customProperties: true,
  iconfont: "md",
  icons: {
    dropdown: "keyboard_arrow_down"
  },
  lang: {
    locales: { ru },
    current: "ru"
  }
});
