import Api, { isRealApi } from "../../../plugins/api";
import BankSolution from "../models/bank-solution";
import _ from "lodash";

export default {
  ReadApplication: function(context, hash) {
    context.state.application.loading = true;
    let promise;

    if (isRealApi()) {
      promise = Api(function(axios) {
        return axios.get("/crediting/applications/get-by-hash/" + hash);
      });
    } else {
      promise = new Promise(resolve => {
        setTimeout(() => {
          // переведёт промис в состояние fulfilled с результатом "result"
          var def = {
            program: 1,
            credit_period: 48,
            rate: 14.8,
            approved_crdt_sum: 1500000,
            approved_crdt_sum_transport: 2000000,
            approved_crdt_sum_insurance: 500000,
            initial_payment: 10000,
            is_residual_payment: 0,
            residual_payment: 0,
            monthly_payment: 30000,
            date_solution: "",
            insurances: [
              {
                product: "СЖ",
                company: "ВСК",
                amount: 150000,
                include_in_credit: null,
                issued_in_bank: null
              },
              {
                product: "GAP",
                company: "ВСК",
                amount: 250000,
                include_in_credit: 1,
                issued_in_bank: 0
              },
              {
                product: "LIFE",
                company: "ВСК",
                amount: 1230000,
                include_in_credit: 1,
                issued_in_bank: 1
              },
              {
                product: "СЖ",
                company: "ВСК",
                amount: 210000,
                include_in_credit: 0,
                issued_in_bank: 0
              }
            ]
          };

          resolve({ data: def });
        }, 1000);
      });
    }

    promise.then(function(response) {
      let sol = new BankSolution();
      sol.setByApi(response.data);
      context.state.application.data = _.cloneDeep(sol);
      context.state.application.response = _.cloneDeep(sol);

      if (response.data.hasOwnProperty("newApplication")) {
        context.commit("setState", {
          prop: "new_hash",
          value: response.data.newApplication || ""
        });
      }
    });

    promise.finally(function() {
      context.state.application.loading = false;
    });

    return promise;
  },
  CreditPeriods: function(context) {
    return new Promise(resolve => {
      context.state.credit_periods.data = [
        { id: 6, name: "6 мес" },
        { id: 12, name: "12 мес" },
        { id: 18, name: "18 мес" },
        { id: 24, name: "24 мес" },
        { id: 30, name: "30 мес" },
        { id: 36, name: "36 мес" },
        { id: 48, name: "48 мес" },
        { id: 60, name: "60 мес" },
        { id: 72, name: "72 мес" },
        { id: 84, name: "84 мес" },
        { id: 108, name: "108 мес" },
        { id: 120, name: "120 мес" },
      ];
      resolve(1);
    });
  },
  ProgramsList: function(context, hash) {
    context.state.program_list.loading = true;

    context.state.program_list.data = [];

    let promise;
    if (isRealApi()) {
      promise = Api(function(axios) {
        return axios.get("/banks_programs/all", {
          params: {
            hash: hash,
            _fields_: ["programName", "crdtProgramId"]
          }
        });
      });

      promise.then(function(response) {
        context.state.program_list.data = [];
        response.data.forEach(item => {
          context.state.program_list.data.push({
            id: parseInt(item.crdtProgramId),
            name: item.programName
          });
        });
      });
    } else {
      promise = new Promise(resolve => {
        setTimeout(() => {
          context.state.program_list.data = [
            { Id: 1, programName: "Кредит на покупку авто" },
            { Id: 2, programName: "Кредит на покупку авто2" },
            { Id: 5, programName: "Кредит на покупку авто5" }
          ];
          resolve(1);
        }, 1000);
      });
    }

    promise.finally(function() {
      context.state.program_list.loading = false;
    });

    return promise;
  },
  SaveBankApplication: function(context, data) {
    context.state.save_application.loading = true;
    context.state.save_application.request = data;

    let promise;
    let app = data.getForApi();

    if (isRealApi()) {
      promise = Api(function(axios) {
        return axios.put("/crediting/applications/" + data.id, app);
      });
    } else {
      promise = new Promise(resolve => {
        setTimeout(() => {
          // переведёт промис в состояние fulfilled с результатом "result"
          var def = {
            program: 1,
            credit_period: 48,
            rate: 14.8,
            approved_crdt_sum: 1500000,
            approved_crdt_sum_transport: 2000000,
            approved_crdt_sum_insurance: 500000,
            initial_payment: 10000,
            is_residual_payment: 0,
            residual_payment: 0,
            monthly_payment: 30000,
            date_solution: "",
            insurances: [
              {
                product: "СЖ",
                company: "ВСК",
                amount: 150000,
                include_in_credit: null,
                issued_in_bank: null
              },
              {
                product: "GAP",
                company: "ВСК",
                amount: 250000,
                include_in_credit: 1,
                issued_in_bank: 0
              },
              {
                product: "LIFE",
                company: "ВСК",
                amount: 1230000,
                include_in_credit: 1,
                issued_in_bank: 1
              },
              {
                product: "СЖ",
                company: "ВСК",
                amount: 210000,
                include_in_credit: 0,
                issued_in_bank: 0
              }
            ]
          };

          resolve({ data: def });
        }, 1000);
      });
    }

    promise.then(function(response) {
      let sol = new BankSolution();
      sol.setByApi(response.data);
      context.state.application.data = _.cloneDeep(sol);
      context.state.application.response = _.cloneDeep(sol);
    });

    promise.finally(function() {
      context.state.save_application.loading = false;
    });

    return promise;
  },
  initFiles({ commit }, hash) {
    let promise = Api(axios => {
      return axios.get(
        "/crediting/applications/get-by-hash/" + hash + "/upload-docs"
      );
    });

    promise.then(({ data = [] }) => commit("initFiles", data));
    return promise;
  },
  getDataFile({ commit }, { hash, id }) {
    let promise = Api(axios => {
      return axios.get(
        "/crediting/applications/get-by-hash/" + hash + "/upload-doc/" + id,
        { responseType: "blob" }
      );
    });

    promise.then(({ data }) =>
      commit("setPropFileByID", {
        prop: "path",
        value: window.URL.createObjectURL(new Blob([data])),
        id
      })
    );

    return promise;
  },
  deleteFile({ commit }, { hash, id }) {
    let promise = Api(axios => {
      return axios.delete(
        "/crediting/applications/get-by-hash/" + hash + "/upload-doc/" + id
      );
    });

    promise.then(({ data = [] }) => commit("initFiles", data));
    return promise;
  }
};
