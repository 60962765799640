<template>
  <div class="params-block__insurance">
    <div v-if="isViewApproved" class="row mb-3" style="margin-top: -37px">
      <div class="col-6" />
      <div class="col text-sm-right">
        <span class="color-label">Заявлено</span>
      </div>
      <div class="col-3 text-sm-right">
        <span class="color-label">Одобрено</span>
      </div>
    </div>

    <div class="row mb-2" v-for="title in titles" :key="title.code">
      <div class="col-6">
        <span class="color-label">{{ title.name }}</span>
      </div>

      <div class="col text-sm-right">
        <UnitRub
          v-if="title.type === 'rub'"
          :value="dataRequested[title.code]"
        />
        <UnitPercent
          v-else-if="title.type === 'percent'"
          :value="dataRequested[title.code]"
        />
        <span v-else />
      </div>

      <div v-if="isViewApproved" class="col-3 text-sm-right">
        <template v-if="!isEditApproved">
          <UnitRub
            v-if="title.type === 'rub'"
            :value="dataApproved[title.code]"
          />
          <UnitPercent
            v-else-if="title.type === 'percent'"
            :value="dataApproved[title.code]"
          />
          <span v-else />
        </template>

        <div v-else class="ml-4">
          <div class="row input-wrapper">
            <div class="col pr-0 text-xs-right">
              <unicus-autonumeric-field
                v-model="dataApproved[title.code]"
                hide-details
                v-validate="'required|min_value:0'"
                data-vv-scope="form"
                data-vv-delay="300"
                :class="{ 'has-error': errors.has(`form.${title.code}`) }"
                :name="title.code"
                :max-value="title.type === 'percent' ? 99.99 : undefined"
              />
            </div>
            <div class="col-auto align-self-center pl-0">
              <i
                class="fal pl-1"
                :class="{
                  'error--text': errors.has(`form.${title.code}`),
                  'fa-ruble-sign': title.type === 'rub',
                  'fa-percent': title.type === 'percent'
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UnitRub from "@/components/units/UnitRub";
import UnitPercent from "@/components/units/UnitPercent";
import { STATUS_VIEW_ORDER_EDIT } from "@/module/insurance/types";

export default {
  name: "ParamsBlock",
  components: { UnitPercent, UnitRub },
  inject: ["$validator"],

  computed: {
    ...mapState("Insurance/Kasko", {
      dataRequested: state => state.application.data.dataRequested,
      dataApproved: state => state.application.data.dataApproved,
      statusViewOrder: state => state.statusViewOrder
    }),
    isDataApproved: self => self.dataApproved.hasData(),
    isEditApproved: self => self.statusViewOrder === self.statusEdit,
    isViewApproved: self => self.isDataApproved || self.isEditApproved
  },

  data: () => ({
    titles: [
      { name: "Страховая стоимость", code: "cost", type: "rub" },
      { name: "Страховая премия", code: "premium", type: "rub" },
      { name: "Скидка за счет СК, %", code: "discountSk", type: "percent" },
      { name: "Скидка за счет КВ, %", code: "discountRacm", type: "percent" }
    ],
    statusEdit: STATUS_VIEW_ORDER_EDIT
  })
};
</script>

<style lang="stylus">
.params-block__insurance {
  label {
    display: none;
  }

  input {
    text-align right;
  }

  .v-text-field {
    padding: 0;
    margin : 0;
  }

  .unicus-field.v-text-field input {
    padding: 0 !important;
  }

  .v-input__slot:before {
    display none
  }

  .input-wrapper {
    background-color: #fffde7;
    padding-top: 6px;
    padding-bottom: 2px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: -5px;
  }
}
</style>
