import isNaN from "lodash/isNaN";
import isDate from "lodash/isDate";
import isEmpty from "./isEmpty";

function parseString(value) {
  return isEmpty(value) ? "" : String(value);
}

function parseNumber(value) {
  if (isEmpty(value)) return 0;

  const number = parseFloat(value);
  return isNaN(number) ? 0 : number;
}

function parseDate(value) {
  if (!isDate(value)) return new Date();
  return new Date(value);
}

const parse = {
  parseString,
  parseNumber,
  parseDate
};

export default parse;
