<template>
  <v-app>
    <v-toolbar class="unicus-head" height="77px" app>
      <div class="container-fluid">
        <div class="row align-items-center" style="height: inherit">
          <div class="col"></div>
          <div class="col-auto">
            <div class="unicus-container px-4">
              <div class="row">
                <div class="col">
                  <unicus-logo />

                  <span class="unicus-title-black pr-1">BELT</span>
                </div>

                <div class="col-auto">
                  <v-btn color="primary" @click="toggleContact">
                    Обратиться в техподдержку
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </v-toolbar>
    <v-content>
      <v-dialog v-model="showError" width="500">
        <v-card class="save-error-modal">
          <v-card-title dark class="headline red white--text">
            Ошибка!
          </v-card-title>

          <v-card-text>
            <span v-for="(error, i) in errors" :key="i">
              {{ error }}
            </span>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="clearErrors">
              Закрыть
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="isContact" width="500">
        <v-card>
          <v-card-title class="headline primary white--text">
            Свяжитесь с нами
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col-6">
                <span class="unicus-title">Номер тех. поддержки:</span>
              </div>
              <div class="col-6">8-800-600-12-92</div>
              <div class="col-6">
                <span class="unicus-title">Эл. адрес тех. поддержки:</span>
              </div>
              <div class="col-6">
                <a class="email" href="mailto:support@elt-poisk.com">
                  support@elt-poisk.com
                </a>
              </div>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="toggleContact">
              Закрыть
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <router-view v-if="init" />
    </v-content>
  </v-app>
</template>

<script>
import { mapMutations, mapState } from "vuex";

function unique(arr) {
  let result = [];

  for (let str of arr) {
    if (!result.includes(str)) result.push(str);
  }

  return result;
}

export default {
  name: "MainLayout",
  data: () => ({
    init: false,
    isContact: false
  }),
  computed: {
    ...mapState("Errors", {
      errors: state => unique(state.errors),
      showError: state => state.showError
    })
  },

  beforeMount() {
    this.$store
      .dispatch("Account/getToken")
      .then(() => {
        this.init = true;
      })
      .catch(() => {
        this.$store.dispatch("Errors/showErrorToken");
        this.$store.dispatch("Account/Logout");
      });
  },

  methods: {
    ...mapMutations("Errors", {
      clearErrors: "clearAll"
    }),
    toggleContact() {
      this.isContact = !this.isContact;
    }
  }
};
</script>

<style lang="stylus" scoped>
.unicus-container
    width: 697px!important;
</style>
