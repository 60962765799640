import Vue from "vue";
import Router from "vue-router";
import MainLayout from "../layouts/main-layout.vue";
import AccountRoute from "../module/account/routes";
import CreditRoute from "@/module/credit/routes";
import InsuranceRoutes from "@/module/insurance/routes";
import ErrorRoute from "@/module/errors/routes";
import store from "./store";

Vue.use(Router);

const children = [AccountRoute, CreditRoute, InsuranceRoutes, ErrorRoute];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: MainLayout,
      children: children
    }
  ]
});

store.commit("Account/Init");

export default router;
