import parse from "@/utilites/parse";
import isEmpty from "@/utilites/isEmpty";

class ModelApplicationData {
  /**
   * Страховая стоимость
   * @type {number, null}
   */
  cost = null;

  /**
   * Страховая премия
   * @type {number, null}
   */
  premium = null;

  /**
   * Скидка за счет КВ %
   * @type {number, null}
   */
  discountRacm = null;

  /**
   * Скидка за счет СК %
   * @type {number, null}
   */
  discountSk = null;

  constructor(data = {}) {
    if (!data) return;
    const { cost, premium, discountRacm, discountSk } = data || {};

    if (!isEmpty(cost)) {
      this.cost = parse.parseNumber(cost);
    }

    if (!isEmpty(premium)) {
      this.premium = parse.parseNumber(premium);
    }

    if (!isEmpty(discountRacm)) {
      this.discountRacm = parse.parseNumber(discountRacm);
    }

    if (!isEmpty(discountSk)) {
      this.discountSk = parse.parseNumber(discountSk);
    }
  }

  getCostNumber() {
    return this.cost || 0;
  }

  getPremiumNumber() {
    return this.premium || 0;
  }

  getDiscountRacmNumber() {
    return this.discountRacm || 0;
  }

  getDiscountSkNumber() {
    return this.discountSk || 0;
  }

  hasData() {
    return (
      this.cost !== null &&
      this.premium !== null &&
      this.discountSk !== null &&
      this.discountRacm !== null
    );
  }
}

export default ModelApplicationData;
